import axios from "axios";
import { IRegisterFormData, IUserData } from '../types/user-types';
import { ILoginFormData } from "../components/LoginForm/loginFormConstants";
import jwtDecode from "jwt-decode"
import * as moment from "moment"
import { ISessionToken } from "../types/backend-types";
import { IKeyData } from "../types/key-types";





// const backendBaseUrl = process.env.REACT_APP_BACKEND_URL;
const backendURL = process.env.REACT_APP_APPLICATION_URL;
const backendProtocol = "localhost" === backendURL ? "http" : "https"
const backendPort = "localhost" === backendURL ? `:${process.env.REACT_APP_DEV_BACKEND_PORT}` : ""
const backendUrlSuffix = "localhost" === backendURL ? "" : "/api"
const backendBaseUrl = `${backendProtocol}://${backendURL}${backendPort}${backendUrlSuffix}`;

console.log(backendBaseUrl)

const createAxiosInstance = (contentType: "application/x-www-form-urlencoded" | "application/json") => {
    return axios.create({
        baseURL: `${backendBaseUrl}`,
        headers: {
            'Content-Type': contentType,
            'Accept': 'application/json',
        },
    });
};

const jsonBackendApiAxiosInstance = createAxiosInstance("application/json")
const urlEncodedApiAxiosInstance = createAxiosInstance("application/x-www-form-urlencoded")


// Add a request interceptor
// TODO remove, for debugging only
jsonBackendApiAxiosInstance.interceptors.request.use((config) => {
    // console.log('Request:', config.method, config.url);
    // console.log('Request Headers:', config.headers);
    // console.log('Request Body:', config.data); // The request body

    return config; // Make sure to return the config object after logging
}, (error) => {
    return Promise.reject(error);
});


export const backendRegistrationService = async (data: IRegisterFormData) => {
    try {
        const response = await jsonBackendApiAxiosInstance.post('/auth/signup', data);
        return response.data
    } catch (error: any) {
        console.error(error); // Handle the error
        throw error
    }
};

const convertJsonToUrlEncoded = (inputObject: { [key: string]: string; }) => {
    const requestDataUrlEncoded = new URLSearchParams();
    for (const key in inputObject) {
        requestDataUrlEncoded.append(key, inputObject[key]);
    }
    return requestDataUrlEncoded
}


const prepareAuthorizationHeader = (sessionTokenString: string) => {
    const decodeSessionToken = (sessionTokenString: string): ISessionToken => {
        const decodedAccessToken = jwtDecode<ISessionToken>(sessionTokenString)
        return decodedAccessToken
    }

    const checkSessionToken = (sessionTokenDecoded: ISessionToken) => {
        return moment.unix(sessionTokenDecoded.exp).toDate() > new Date()
    }
    const decodedAccessToken = decodeSessionToken(sessionTokenString);
    const authorizationHeader: Partial<Record<string, string>> = {}; // Initialize an empty object
    if (checkSessionToken(decodedAccessToken)) {
        const encodedToken = JSON.parse(sessionTokenString)
        authorizationHeader["Authorization"] = `Bearer ${encodedToken}`  // 3
    } else {
        alert('Your session has expired')
    }
    return authorizationHeader
}

export const backendLoginService = async (data: ILoginFormData) => {
    try {
        // return fakeLogin()
        const requestDataFormatted = convertJsonToUrlEncoded({ ...data })
        const response = await urlEncodedApiAxiosInstance.post('/auth/login', requestDataFormatted);
        const sessionToken = JSON.stringify(response.data["access_token"])
        return sessionToken
    } catch (error) {
        console.error(error); // Handle the error
        throw error
    }
};

export const backendFetchUserData = async (sessionTokenString: string) => {

    try {
        const authorizationHeader = prepareAuthorizationHeader(sessionTokenString)
        const response = await jsonBackendApiAxiosInstance.get('/auth/me', {
            headers: authorizationHeader,
        });
        const userData: IUserData = response.data
        return userData
    } catch (error) {
        console.error(error); // Handle the error
        throw error
    }

}

export const backendFetchNewApiKey = async (sessionTokenString: string) => {

    try {
        const authorizationHeader = prepareAuthorizationHeader(sessionTokenString)
        const response = await jsonBackendApiAxiosInstance.get('/keys/me/create', {
            headers: authorizationHeader,
        });
        const userApiKeys:IKeyData = response.data
        return userApiKeys
    } catch (error) {
        console.error(error); // Handle the error
        throw error
    }

}

export const backendFetchUpdateApiKey = async (sessionTokenString: string) => {

    try {
        const authorizationHeader = prepareAuthorizationHeader(sessionTokenString)
        const response = await jsonBackendApiAxiosInstance.get('/keys/me/update', {
            headers: authorizationHeader,
        });
        const userApiKeys:IKeyData = response.data
        return userApiKeys
    } catch (error) {
        console.error(error); // Handle the error
        throw error
    }

}

